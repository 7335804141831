import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate, useNavigate } from "react-router-dom";

export function HandleCallbackPage() {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useKindeAuth();

  if (isAuthenticated && !isLoading) navigate("/dashboard/statistics");

  if (!isAuthenticated) return <Navigate to="/" />;

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="h-10 w-10 border-4 border-gray-200 border-b-gray-500 rounded-[50%] animate-spin" />
    </div>
  );
}
