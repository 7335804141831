import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

export function Layout() {
  return (
    <>
      <Navbar />
      <main className="p-4 md:ml-52 bg-white min-h-screen">
        <Outlet />
      </main>
    </>
  );
}
