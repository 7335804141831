import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import WelcomePage from "./pages/welcome";
import { Layout } from "./modules/layout/layout";
import { ProtectedRoute } from "./modules/auth/ProtectedRoute";
import { MainPage } from "./pages/dashboard/main";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { useMemo } from "react";
import { HandleCallbackPage } from "./pages/handleCallback";
import { BikesPage } from "./pages/dashboard/bikes";
import { NewBikePage } from "./pages/dashboard/bikes.new";
import { ShowBikePage } from "./pages/dashboard/bikes.$id";
import { ReportsPage } from "./pages/dashboard/reports";
import { NewReportPage } from "./pages/dashboard/reports.new";
import { ShowReportPage } from "./pages/dashboard/reports.$id";
import { UsersPage } from "./pages/dashboard/users";
import NotFound from "./pages/not-found";
import Error404Page from "./pages/dashboard/404";
import { LogOutPage } from "./pages/dashboard/logout";
import { CLIENT_ID, KINDE_DOMAIN } from "./constants";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 60 minutes
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  const callback = useMemo(
    () => `${window.location.origin}/dashboard/statistics`,
    []
  );

  return (
    <KindeProvider
      clientId={CLIENT_ID}
      domain={KINDE_DOMAIN}
      redirectUri={callback}
      logoutUri={window.location.origin}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Navigate to="sign-in" />} />
            <Route path={"sign-in"} element={<WelcomePage />} />
            <Route path={"handleCallback"} element={<HandleCallbackPage />} />

            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              <Route index element={<Navigate to="statistics" />} />
              <Route path="statistics" element={<MainPage />} />

              <Route path="bikes" element={<BikesPage />} />
              <Route path="bikes/new" element={<NewBikePage />} />
              <Route path="bikes/:id" element={<ShowBikePage />} />

              <Route path="reports" element={<ReportsPage />} />
              <Route path="reports/new" element={<NewReportPage />} />
              <Route path="reports/:id" element={<ShowReportPage />} />

              <Route path="users" element={<UsersPage />} />

              <Route path="logout" element={<LogOutPage />} />

              {/*<Route path="profile" element={<ProfilePage />} />*/}

              <Route path="*" element={<Error404Page />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </KindeProvider>
  );
}

export default App;
