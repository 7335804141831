import { Link } from "react-router-dom";
import { BikeType } from "../../modules/api/interfaces/bike";
import { EyeIcon, PencilIcon } from "@heroicons/react/24/solid";
import useBikes from "../../modules/api/hooks/useBikes";

export function BikesPage() {
  // Call the hook at the top of the component
  const { bikes, isLoading } = useBikes();

  return (
    <div className="py-12 px-4 max-w-6xl mx-auto">
      <div className="flex items-center justify-between">
        <div>
          <h1>Bikes</h1>
          <p className="text-secondary">
            Create, edit and delete bikes here on this page.
          </p>
        </div>
        <div>
          <Link to="./new" className="btn">
            Create bike
          </Link>
        </div>
      </div>

      <div className="relative overflow-x-auto rounded-lg mt-8">
        <table className="w-full text-gray-500">
          <thead className="text-xs text-left text-gray-700 uppercase">
            <tr>
              <th>Frame number</th>
              <th>Bike type</th>
              <th>Missing?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    <td colSpan={6} className="py-4">
                      <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                    </td>
                  </tr>
                ))
              : bikes?.map((bike, index) => (
                  <tr key={index} className="text-left text-balance">
                    <td className="px-6 py-4">{bike.frameNumber}</td>
                    <td className="px-6 py-4">{BikeType[bike.bikeType]}</td>
                    <td className="px-6 py-4">
                      <span
                        className={`px-2 py-1 rounded-full text-white ${
                          bike.reports?.some((bike) => bike.active)
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        {bike.reports?.some((bike) => bike.active)
                          ? "Yes"
                          : "No"}
                      </span>
                    </td>
                    <td className="w-32 text-right">
                      <div>
                        <Link className="btn-icon" to={bike.frameNumber}>
                          <EyeIcon className="h-5 w-5" />
                        </Link>
                        <Link className="btn-icon" to={bike.frameNumber}>
                          <PencilIcon className="h-5 w-5" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BikesPage;
