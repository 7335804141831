import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate } from "react-router-dom";

export function WelcomePage() {
  const { login, isAuthenticated } = useKindeAuth();

  if (isAuthenticated) return <Navigate to="/dashboard/statistics" />;
  return (
    <div className="h-screen flex items-center justify-center bg-white">
      <div className="max-w-md w-full text-center">
        <img
          src="/swapfiets-logo.png"
          alt="swapfiets"
          width="68"
          height="68"
          className="mx-auto mb-2 rounded-lg"
        />
        <h1 className="text-lg font-medium text-black">SwapClaim</h1>
        <p className="text-slate-500">Login to continue</p>
        <button
          onClick={() => login()}
          type="button"
          className="btn mt-4 min-w-[240px]"
        >
          Login
        </button>
        {true ? (
          <>
            <p className="mt-4 text-slate-500">or request access to continue</p>
            <a
              href="https://swapclaim.kinde.com/knock-knock"
              rel="noopener noreferrer"
              type="button"
              className="btn mt-4 min-w-[240px]"
            >
              Request accesss
            </a>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default WelcomePage;
