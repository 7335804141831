import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IReport } from "../interfaces/report";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { API_URL } from "../../../constants";

// React Query hook
export const useReports = () => {
  const { getToken } = useKindeAuth();

  const getAllReports = async (): Promise<IReport[]> => {
    try {
      const accessToken = await getToken?.();
      const { data } = await axios.get<IReport[]>(API_URL + "/reports", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error: any) {
      console.error("Failed to fetch reports:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["reports"],
    queryFn: getAllReports,
    select: (data) => data || [],
  });
  return { isLoading, reports: data, error };
};
export default useReports;
