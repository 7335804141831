import { Link, useNavigate } from "react-router-dom";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { IUser } from "../../modules/api/interfaces/user";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export function UsersPage() {
  //const { users, isLoading } = useUsers();
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();
  if (!(getPermission && getPermission("VIEW_USERS")?.isGranted)) {
    navigate("/dashboard/statistics");
  }

  const users: IUser[] = [];
  return (
    <>
      <div className="py-12 px-4 max-w-5xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1 className="text-2xl font-medium mb-1 text-black dark:text-white">
              Users
            </h1>
            <p className="text-gray-400 text-sm">
              Create, edit and delete users here on this page.
            </p>
          </div>
          <div>
            <Link to="./new" className="btn">
              Create user
            </Link>
          </div>
        </div>
        <div className="relative overflow-x-auto rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">User ID</th>
                <th className="px-6 py-3">Email</th>
                <th className="px-6 py-3">Name</th>
                <th className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {users?.map((bike, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 last:border-0"
                >
                  <td className="px-6 py-4">{bike.userId}</td>
                  <td className="px-6 py-4">{bike.email}</td>
                  <td className="px-6 py-4">{bike.name}</td>
                  <td>
                    <div className="flex space-x-2 justify-end mr-8">
                      <Link
                        className="inline-flex items-center justify-center rounded-md bg-white dark:bg-gray-800 dark:text-gray-400 text-slate-500 hover:bg-slate-100 hover:dark:bg-gray-600 active:dark:bg-gray-700 active:bg-slate-200 text-sm font-medium p-2.5"
                        to={"1"}
                      >
                        <EyeIcon className="h-5 w-5" />
                      </Link>
                      <Link
                        className="inline-flex items-center justify-center rounded-md bg-white dark:bg-gray-800 dark:text-gray-400 text-slate-500 hover:bg-slate-100 hover:dark:bg-gray-600 active:dark:bg-gray-700 active:bg-slate-200 text-sm font-medium p-2.5"
                        to={"2"}
                      >
                        <PencilIcon className="h-5 w-5" />
                      </Link>

                      <button
                        className="inline-flex items-center justify-center rounded-md bg-white dark:bg-gray-800 dark:text-gray-400 text-slate-500 hover:bg-slate-100 hover:dark:bg-gray-600 active:dark:bg-gray-700 active:bg-slate-200 text-sm font-medium p-2.5"
                        onClick={() => {}}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
