import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { IMonthlyStatistics } from "../interfaces/monthly_statistics";
import { API_URL } from "../../../constants";

// React Query hook to fetch statistics with access token
export const useStatistics = () => {
  const { getToken } = useKindeAuth();

  const fetchBikes = async (): Promise<IMonthlyStatistics> => {
    try {
      const accessToken = await getToken?.();
      const { data } = await axios.get<IMonthlyStatistics>(
        API_URL + "/statistics",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return data;
    } catch (error: any) {
      console.error("Failed to fetch statistics:", error);
      throw new Error(error.message);
    }
  };

  const { isLoading, data, error } = useQuery({
    queryKey: ["statistics"],
    queryFn: fetchBikes,
    select: (data) => data || [],
  });

  return { isLoading, statistics: data, error };
};

export default useStatistics;
