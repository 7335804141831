import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BikeType } from "../interfaces/bike";
import { useNavigate } from "react-router-dom";
import { IBikeForm } from "../../../pages/dashboard/bikes.new";
import { API_URL } from "../../../constants";

const createNewBike = async (frameNumber: string, bikeType: BikeType) => {
  try {
    await axios.post(API_URL + "/bikes", {
      frameNumber: frameNumber,
      bikeType: bikeType,
    });
  } catch (error) {
    console.error("Failed to create bike:", error);
    throw error;
  }
};

interface IUseDeleteBikeProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useCreateBike = (props?: IUseDeleteBikeProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["post", "bike"],
    mutationFn: (bike: IBikeForm) =>
      createNewBike(bike.frameNumber, bike.bikeType),
    onError: (error: Error) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["bikes"] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate("/dashboard/bikes");
    },
  });
};
