import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IReportForm } from "../../../pages/dashboard/reports.new";
import { API_URL } from "../../../constants";

const createNewReport = async (
  journalNumber: string,
  date: Date,
  description: string,
  active: boolean,
  bikeReference: string
) => {
  try {
    await axios.post(API_URL + "/reports", {
      journalNumber: journalNumber,
      date: date,
      description: description,
      active: active,
      bikeReference: bikeReference,
    });
  } catch (error) {
    console.error("Failed to create report:", error);
    throw error;
  }
};

interface IUseDeleteReportProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useCreateReport = (props?: IUseDeleteReportProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["post", "report"],
    mutationFn: (report: IReportForm) =>
      createNewReport(
        report.journalNumber,
        new Date(report.date),
        report.description,
        report.active === "true",
        report.bikeReference
      ),
    onError: (error: Error) => {
      console.error(error);
      if (props?.onError) {
        props.onError(error);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["reports"] });
      if (props?.onSuccess) {
        props.onSuccess();
      }
      navigate("/dashboard/reports");
    },
  });
};
