import { useState } from "react";
import { useCreateReport } from "../../modules/api/hooks/useCreateReport";

export interface IReportForm {
  journalNumber: string;
  date: string;
  description: string;
  active: string;
  bikeReference: string;
}

export function NewReportPage() {
  const createReportMutation = useCreateReport();
  const [form, setForm] = useState<IReportForm>({
    journalNumber: "",
    date: "",
    description: "",
    active: "",
    bikeReference: "",
  });
  const [error, setError] = useState("");

  const handleSubmit = () => {
    let errorMessage = "";

    if (!form.journalNumber) {
      errorMessage += "Journal number is required.\n";
    }
    if (!form.bikeReference) {
      errorMessage += "Bike reference is required.\n";
    }
    if (!form.date) {
      errorMessage += "Date is required.\n";
    }
    if (!form.active || form.active === "default") {
      errorMessage += "Active? is required.\n";
    }
    if (!form.description) {
      errorMessage += "Description is required.\n";
    }

    if (errorMessage) {
      setError(errorMessage.trim());
      return;
    }

    createReportMutation.mutate({
      journalNumber: form.journalNumber,
      date: form.date,
      description: form.description,
      active: form.active,
      bikeReference: form.bikeReference,
    });
  };

  return (
    <>
      <div className="py-12 px-4 max-w-6xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1>Create New Report</h1>
            <p>Create a new report on this page.</p>
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div className="w-full">
            <label
              htmlFor="journal-number"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Journal number
            </label>
            <input
              type="text"
              name="journal-number"
              id="journal-number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type the police journal number"
              required
              value={form.journalNumber}
              onChange={(e) =>
                setForm({ ...form, journalNumber: e.target.value })
              }
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="bike-reference"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Bike reference
            </label>
            <input
              type="text"
              name="bike-reference"
              id="bike-reference"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type frame number of the bike"
              required
              value={form.bikeReference}
              onChange={(e) =>
                setForm({ ...form, bikeReference: e.target.value })
              }
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="bike-type"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Date
            </label>
            <input
              type="date"
              name="date"
              id="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type frame number of the bike"
              required
              value={form.date}
              onChange={(e) => setForm({ ...form, date: e.target.value })}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="bike-reference"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Active?
            </label>
            <select
              id="bike-reference"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              required
              value={form.active}
              onChange={(e) => setForm({ ...form, active: e.target.value })}
            >
              <option value="default">Select an option</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="journal-number"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Description
            </label>
            <textarea
              name="description"
              id="description"
              className="bg-gray-50 h-48 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type the details of the police report here"
              required
              value={form.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
          </div>
        </div>
        <button onClick={handleSubmit} className="btn mt-4">
          Create report
        </button>
        {error && (
          <div className="text-red-500 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
