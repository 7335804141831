import {
  //UserGroupIcon,
  DocumentTextIcon,
  HomeIcon,
  KeyIcon,
  MagnifyingGlassIcon,
  ArrowLeftEndOnRectangleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchModal from "../components/searchmodal";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export default function Navbar() {
  const navigate = useNavigate();
  const { getPermission } = useKindeAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <aside
        id="default-sidebar"
        className="fixed top-0 left-0 z-40 w-52 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidenav"
      >
        <div className="overflow-y-auto py-5 px-3 h-full bg-customBlue">
          <img
            src="/swapfiets-logo.png"
            alt="swapfiets"
            width="68"
            height="68"
            className="mx-auto mb-2 rounded-lg"
          />
          <ul className="space-y-2">
            <li>
              <button
                onClick={() => setIsModalOpen(true)}
                className="flex items-center p-2 w-full text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  "
              >
                <MagnifyingGlassIcon className="flex items-center p-2 size-10 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  " />

                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Search
                </span>
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/dashboard")}
                className="flex items-center p-2 w-full text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  "
              >
                <HomeIcon className="flex items-center p-2 size-10 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  " />

                <span className="ml-3">Dashboard</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/dashboard/bikes")}
                className="flex items-center p-2 w-full text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  "
              >
                <KeyIcon className="flex items-center p-2 size-10 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  " />

                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Bikes
                </span>
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/dashboard/reports")}
                className="flex items-center p-2 w-full text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  "
              >
                <DocumentTextIcon className="flex items-center p-2 size-10 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  " />

                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Reports
                </span>
              </button>
            </li>
            {getPermission && getPermission("VIEW_USERS")?.isGranted ? (
              <li
                onClick={() => navigate("/dashboard/users")}
                className="flex items-center p-2 w-full text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  "
              >
                <UserGroupIcon className="flex items-center p-2 size-10 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  " />
                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Users
                </span>
              </li>
            ) : null}
            <li>
              <button
                onClick={() => navigate("logout")}
                className="flex items-center p-2 w-full text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  "
              >
                <ArrowLeftEndOnRectangleIcon className="flex items-center p-2 size-10 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-customDarkBlue  " />

                <span className="flex-1 ml-3 text-left whitespace-nowrap">
                  Logout
                </span>
              </button>
            </li>
          </ul>
        </div>
      </aside>
      {isModalOpen && (
        <div className="fixed inset-0 z-50">
          <SearchModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onOpen={() => setIsModalOpen(true)}
          />
        </div>
      )}
    </>
  );
}
