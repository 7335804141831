import { useEffect } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingSpinner from "../../modules/components/LoadingSpinner";

export function LogOutPage() {
  const { logout } = useKindeAuth();

  useEffect(() => {
    logout();
  });

  return <LoadingSpinner />;
}
