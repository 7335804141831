import { IReport } from "./report";

export interface IBike {
  frameNumber: string;
  bikeType: BikeType;
  reports?: IReport[];
}

export enum BikeType {
  Default = -1,
  Original = 0,
  Deluxe7 = 1,
  Power1 = 2,
  Power7 = 3,
}
