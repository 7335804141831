import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import useBikes from "../../modules/api/hooks/useBikes";
import { BikeType } from "../../modules/api/interfaces/bike";

export function ShowBikePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { bikes } = useBikes();
  const bike = bikes?.find((bike) => bike.frameNumber === id);

  return (
    <>
      <div className="py-12 px-4 max-w-6xl mx-auto h-full">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1 className="text-2xl font-medium mb-1 text-black">
              Bike {bike?.frameNumber}
            </h1>
            <p className="text-gray-400 text-sm">
              See bike details and reports associated with the bike here on this
              page.
            </p>
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div className="sm:col-span-2">
            <label
              htmlFor="frame-number"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Frame number
            </label>
            <input
              type="text"
              name="frame-number"
              id="frame-number"
              className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type product name"
              value={bike?.frameNumber}
              disabled
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="bike-type"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Bike type
            </label>
            <input
              type="text"
              name="bike-type"
              id="bike-type"
              className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              value={
                bike?.bikeType !== undefined
                  ? BikeType[bike.bikeType]
                  : "Default"
              }
              disabled
            />
          </div>
        </div>
        <p className="block mt-6 mb-2 text-sm font-medium text-gray-900">
          Reports
        </p>
        <div className="relative overflow-x-auto rounded-lg">
          <table className="w-full text-gray-500">
            <thead className="text-xs text-left text-gray-700 uppercase">
              <tr>
                <th className="px-10 py-3">Journal number</th>
                <th className="px-12 py-3">Date</th>
                <th className="px-6 py-3">Description</th>
                <th className="px-6 py-3">Active?</th>
              </tr>
            </thead>
            <tbody>
              {bike?.reports
                ?.sort((a, b) => Number(b.active) - Number(a.active))
                .map((report, index) => (
                  <tr key={index} className="border-b last:border-0">
                    <td
                      className="px-6 py-4 hover:underline"
                      onClick={() =>
                        navigate("/dashboard/reports/" + report.journalNumber)
                      }
                    >
                      {report.journalNumber}
                    </td>
                    <td className="px-6 py-4">
                      {format(new Date(report.date), "dd-MM-yyyy HH:mm", {
                        locale: da,
                      })}
                    </td>

                    <td className="px-6 py-4">{report.description}</td>
                    <td className="px-6 py-4">
                      <span
                        className={`px-2 py-1 rounded-full text-white ${
                          report.active ? "bg-green-500" : "bg-red-500"
                        }`}
                      >
                        {report.active ? "Yes" : "No"}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
