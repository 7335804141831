import { Link } from "react-router-dom";
import { EyeIcon, PencilIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import useReports from "../../modules/api/hooks/useReports";

export function ReportsPage() {
  const { reports, isLoading } = useReports();
  return (
    <>
      <div className="py-12 px-4 max-w-6xl mx-auto">
        <div className="flex items-center justify-between">
          <div>
            <h1>Reports</h1>
            <p className="text-secondary">
              Create, edit and delete reports here on this page.
            </p>
          </div>
          <div>
            <Link to="./new" className="btn">
              Create report
            </Link>
          </div>
        </div>
        <div>
          <div className="relative overflow-x-auto rounded-lg mt-8">
            <table className="w-full text-gray-500">
              <thead className="text-xs text-left text-gray-700 uppercase">
                <tr>
                  <th>Journal number</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Active?</th>
                  <th>Bike number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isLoading
                  ? Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td colSpan={6} className="py-4">
                          <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                        </td>
                      </tr>
                    ))
                  : reports?.map((report, index) => (
                      <tr key={index} className="text-left text-balance">
                        <td className="w-32">{report.journalNumber}</td>
                        <td className="w-24">
                          {format(new Date(report.date), "dd-MM-yyyy", {
                            locale: da,
                          })}
                        </td>
                        <td>{report.description}</td>
                        <td className="w-24 px-4">
                          <span
                            className={`py-1 px-2 rounded-lg text-white ${
                              report.active ? "bg-green-500" : "bg-red-500"
                            }`}
                          >
                            {report.active ? "Yes" : "No"}
                          </span>
                        </td>
                        <td className="w-24">{report.bikeReference}</td>

                        <td className="w-32 text-right">
                          <div>
                            <Link
                              className="btn-icon"
                              to={report.journalNumber}
                            >
                              <EyeIcon className="h-5 w-5" />
                            </Link>
                            <Link
                              className="btn-icon"
                              to={report.journalNumber}
                            >
                              <PencilIcon className="h-5 w-5" />
                            </Link>

                            {/*<button className="btn-icon" onClick={() => {}}>
                            <TrashIcon className="h-5 w-5" />
                          </button>*/}
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
