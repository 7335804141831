import { useState } from "react";
import { useCreateBike } from "../../modules/api/hooks/useCreateBike";
import { BikeType } from "../../modules/api/interfaces/bike";

export interface IBikeForm {
  frameNumber: string;
  bikeType: BikeType;
}

export function NewBikePage() {
  const createBikeMutation = useCreateBike();
  const [form, setForm] = useState<IBikeForm>({
    frameNumber: "",
    bikeType: -1,
  });
  const [error, setError] = useState("");

  const handleSubmit = () => {
    let errorMessage = "";

    if (!form.frameNumber) {
      errorMessage += "Frame number is required.\n";
    }
    if (form.bikeType === -1) {
      errorMessage += "Bike type is required.\n";
    }

    if (errorMessage) {
      setError(errorMessage.trim());
      return;
    }
    createBikeMutation.mutate({
      frameNumber: form.frameNumber,
      bikeType: form.bikeType,
    });
  };

  return (
    <>
      <div className="py-12 px-4 max-w-6xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1>Create New Bike</h1>
            <p>Create a new bike on this page.</p>
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div className="sm:col-span-2">
            <label
              htmlFor="frame-number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Frame number
            </label>
            <input
              type="text"
              name="name"
              id="frame-number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type frame number of the bike"
              required
              value={form.frameNumber}
              onChange={(e) =>
                setForm({ ...form, frameNumber: e.target.value })
              }
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="bike-type"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Bike type
            </label>
            <select
              id="bike-type"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              required
              value={form.bikeType}
              onChange={(e) =>
                setForm({ ...form, bikeType: Number(e.target.value) })
              }
            >
              <option value={BikeType.Default} disabled>
                Select an option
              </option>
              <option value={BikeType.Original}>Original</option>
              <option value={BikeType.Deluxe7}>Deluxe7</option>
              <option value={BikeType.Power1}>Power1</option>
              <option value={BikeType.Power7}>Power7</option>
            </select>
          </div>
        </div>
        <button onClick={handleSubmit} className="btn mt-4">
          Create bike
        </button>
        {error && (
          <div className="text-red-500 whitespace-pre-wrap">{error}</div>
        )}
      </div>
    </>
  );
}
