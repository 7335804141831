import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

export interface ProtectedRouteProps {
  children: ReactElement;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { isAuthenticated, isLoading } = useKindeAuth();

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="/sign-in" />;
  }
  return children;
}
