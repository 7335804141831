import { useParams } from "react-router-dom";
import { format } from "date-fns";
import useReports from "../../modules/api/hooks/useReports";

export function ShowReportPage() {
  const { id } = useParams();
  const { reports } = useReports();
  const report = reports?.find((report) => report.journalNumber === id);

  return (
    <>
      <div className="py-12 px-4 max-w-6xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h1>Report {report?.journalNumber}</h1>
            <p>See report details here on this page.</p>
          </div>
        </div>
        <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
          <div className="w-full">
            <label
              htmlFor="journal-number"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Journal number
            </label>
            <input
              type="text"
              name="journal-number"
              id="journal-number"
              className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type the police journal number"
              required
              value={report?.journalNumber}
              disabled
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="bike-reference"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Bike reference
            </label>
            <input
              type="text"
              name="bike-reference"
              id="bike-reference"
              className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type frame number of the bike"
              required
              value={report?.bikeReference}
              disabled
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="bike-type"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Date
            </label>
            <input
              type="text"
              name="date"
              id="date"
              className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              placeholder="Type frame number of the bike"
              required
              value={
                report?.date ? format(new Date(report.date), "dd/MM/yyyy") : ""
              }
              disabled
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="bike-reference"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Active?
            </label>
            <input
              id="bike-reference"
              className="disabled:opacity-70 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
              required
              value={report?.active ? "Yes" : "No"}
              disabled
            ></input>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="journal-number"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Description
            </label>
            <textarea
              name="description"
              id="description"
              className="disabled:opacity-70 h-48 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5   dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Type the details of the police report here"
              required
              value={report?.description}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
}
