import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import useStatistics from "../../modules/api/hooks/useStatistics";

export function MainPage() {
  const { statistics, isLoading } = useStatistics();
  return (
    <div className="py-12 px-4 max-w-6xl mx-auto">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-4xl">SwapClaim Dashboard</h1>
          <p className="text-secondary">
            See all statistics and data here on this page.
          </p>
        </div>
      </div>
      <div className="py-8 mx-auto text-center">
        <dl className="grid gap-8 mx-auto text-gray-900 sm:grid-cols-4">
          {isLoading ? (
            Array.from({ length: 4 }).map((_, index) => (
              <div key={index}>
                <p className="h-36 bg-gray-200 rounded animate-pulse"></p>
              </div>
            ))
          ) : (
            <>
              <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                  {statistics?.totalBikes}
                </dt>
                <dd className="font-light text-gray-500">Total Bikes</dd>
                <dd className="inline-flex items-center">
                  {(statistics?.percentageChangeBikes ?? 0) > 0 ? (
                    <>
                      <ChevronUpIcon className="w-4 h-4 text-green-500" />
                      <p className="text-xs font-light text-green-500 me-1.5">
                        {statistics?.percentageChangeBikes.toFixed(1) + "%"}
                      </p>
                    </>
                  ) : (
                    <>
                      <ChevronDownIcon className="w-4 h-4 text-red-500" />
                      <p className="text-xs font-light text-red-500 me-1.5">
                        {statistics?.percentageChangeBikes.toFixed(1) + "%"}
                      </p>
                    </>
                  )}
                  <p className="text-xs font-light text-gray-500">
                    Since last month
                  </p>
                </dd>
              </div>
              <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                  {statistics?.totalReports}
                </dt>
                <dd className="font-light text-gray-500">Total Reports</dd>
                <dd className="inline-flex items-center">
                  {(statistics?.percentageChangeReports ?? 0) > 0 ? (
                    <>
                      <ChevronUpIcon className="w-4 h-4 text-green-500" />
                      <p className="text-xs font-light text-green-500 me-1.5">
                        {statistics?.percentageChangeReports.toFixed(1) + "%"}
                      </p>
                    </>
                  ) : (
                    <>
                      <ChevronDownIcon className="w-4 h-4 text-red-500" />
                      <p className="text-xs font-light text-red-500 me-1.5">
                        {statistics?.percentageChangeReports.toFixed(1) + "%"}
                      </p>
                    </>
                  )}
                  <p className="text-xs font-light text-gray-500">
                    Since last month
                  </p>
                </dd>
              </div>
              <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                  {statistics?.activeReports}
                </dt>
                <dd className="font-light text-gray-500">Active Reports</dd>
                <dd className="inline-flex items-center">
                  {(statistics?.percentageChangeArchivedReports ?? 0) > 0 ? (
                    <>
                      <ChevronUpIcon className="w-4 h-4 text-green-500" />
                      <p className="text-xs font-light text-green-500 me-1.5">
                        {statistics?.percentageChangeActiveReports.toFixed(1) +
                          "%"}
                      </p>
                    </>
                  ) : (
                    <>
                      <ChevronDownIcon className="w-4 h-4 text-red-500" />
                      <p className="text-xs font-light text-red-500 me-1.5">
                        {statistics?.percentageChangeActiveReports.toFixed(1) +
                          "%"}
                      </p>
                    </>
                  )}
                  <p className="text-xs font-light text-gray-500">
                    Since last month
                  </p>
                </dd>
              </div>
              <div className="block p-6 bg-white border border-gray-200 rounded-lg shadow">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                  {statistics?.archivedReports}
                </dt>
                <dd className="font-light text-gray-500">Archived Reports</dd>
                <dd className="inline-flex items-center">
                  {(statistics?.percentageChangeArchivedReports ?? 0) > 0 ? (
                    <>
                      <ChevronUpIcon className="w-4 h-4 text-green-500" />
                      <p className="text-xs font-light text-green-500 me-1.5">
                        {statistics?.percentageChangeArchivedReports.toFixed(
                          1
                        ) + "%"}
                      </p>
                    </>
                  ) : (
                    <>
                      <ChevronDownIcon className="w-4 h-4 text-red-500" />
                      <p className="text-xs font-light text-red-500 me-1.5">
                        {statistics?.percentageChangeArchivedReports.toFixed(
                          1
                        ) + "%"}
                      </p>
                    </>
                  )}
                  <p className="text-xs font-light text-gray-500">
                    Since last month
                  </p>
                </dd>
              </div>
            </>
          )}
        </dl>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-2" />
      Under construction...
    </div>
  );
}
