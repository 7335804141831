export function NotFound() {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="text-black max-w-md w-full text-center">
        <h1 className="font-bold">404</h1>
        <p>The requested page was not found</p>
      </div>
    </div>
  );
}

export default NotFound;
